// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as bootstrap from "bootstrap"
import * as ClipboardJS from "clipboard";
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const feather = require("feather-icons");
var tooltipList;
var clipboard;

// Clean up objects
$(document).on('turbolinks:before-cache', function() {
  tooltipList.forEach(function(t) {
    if (t) { t.dispose(); }
  })
  if (clipboard) { clipboard.destroy(); }
})


$(document).on('turbolinks:load', function() {
  feather.replace();

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, {
      trigger: 'click'
    })
  })

  if (typeof $.uf === 'undefined') {
      $.uf = {};
  }

  $.uf.copy = function (button) {
      var _this = this;

      clipboard = new ClipboardJS(button, {
          text: function(trigger) {
              var el = $(trigger).closest('.js-copy-container').find('.js-copy-target');
              if (el.is(':input')) {
                  return el.val();
              } else {
                  return el.html();
              }
          }
      });

      clipboard.on('success', function(e) {
          setTooltip(e.trigger, 'Copied');
          hideTooltip(e.trigger);
      });

      function setTooltip(btn, message) {
          var tooltip = bootstrap.Tooltip.getInstance($(btn));
          if (tooltip) { tooltip.show(); }
      }
      
      function hideTooltip(btn) {
          setTimeout(function() {
            var tooltip = bootstrap.Tooltip.getInstance($(btn));
            if (tooltip) { tooltip.hide(); }
          }, 1000);
      }
  };

  // Link all copy buttons
  $.uf.copy('.js-copy-trigger');
})